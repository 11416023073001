<template>
    <div class="pd20x flex1">
      <Deliver :visible="deliverOpen" @close="deliverOpen = false"></Deliver>
      <div class="contentBox h100pct ofA">
        <div class="filter" style="padding:10px 20px">
          <!-- <div>
            <a-button type="primary" @click="openClasses">开课</a-button>
          </div> -->
          <div>
            <label>搜索：</label>
            <a-select v-model="selectVal" style="width: 120px" >
              <a-select-option :value="item.val" v-for="(item,index) in option" :key="index">
                {{item.name}}
              </a-select-option>
            </a-select>
            <a-input-search placeholder="请输入搜索内容" v-model="searchInput" style="width: 300px" @search="onSearch" class="mr10px"/>
          </div>
        </div>
        <a-table
          :columns="columns"
          :data-source="data"
          :rowKey="'uid'"
          :pagination="{pageSize: page.pageSize, total}"
          @change="changePage"
        >
          <template slot="headimgurl" slot-scope="text">
            <a-avatar icon="user" :src="text"/>
          </template>
          <template slot="nickname" slot-scope="text,record">
            <div>{{ record.nickname }}</div>
            <div>{{ record.mobile }}</div>
          </template>
            <!-- <template slot="status" slot-scope="text">
                <a-date-picker :defaultValue="momentDay(text)" @change="onChange" style="width:130px" />
            </template>
            <template slot="content2" slot-scope="text,record">
                <a-input :defaultValue="text" style="width: 130px" @blur="changeNum(e,record)"></a-input>
            </template> -->
          <template slot="isAgent" slot-scope="text,record">
            <a-switch :default-checked="record.isAgent == 1? true :false" @change="(e) => {changeAdmin(e, record.id)}"/>
          </template>
          <template slot="operation" slot-scope="text, record">
            <a-space>
              <!-- <span class="csP" style="color:#7ebae5" >编辑</span>
              <span class="csP" style="color:#7ebae5" >复制</span> -->
              <span class="csP" style="color:#7ebae5" @click="openClasses(record)">试卷</span>
              <!-- <span class="csP" style="color:#7ebae5" >发货</span>
              <span class="csP" style="color:#7ebae5" >删除</span> -->
              </a-space>
          </template>
        </a-table>
        <!-- <span class="csP mr10px" style="color:#7ebae5" >编辑</span>
        <span class="csP mr10px" style="color:#7ebae5" >复制</span>
        <span class="csP mr10px" style="color:#7ebae5" >开通</span>
        <span class="csP mr10px" style="color:#7ebae5" @click="deliverOpen = true">发货</span>
        <span class="csP" style="color:#ff5f4e" >删除</span>
        <span class="csP" style="color:#ff5f4e" @click="$router.push('/exam/studentDetail')">学员详情</span> -->
      </div>
    </div>
</template>
<script>
const columns = [
  { title: 'ID', dataIndex: 'id', align: 'center' },
  { title: '头像', dataIndex: 'headImg', align: 'center', scopedSlots: { customRender: 'headimgurl' } },
  { title: '学号', dataIndex: 'num', align: 'center' },
  { title: '信息', dataIndex: 'nickname', scopedSlots: { customRender: 'nickname' }, align: 'center' },
  // { title: '地址', dataIndex: 'content4', align: 'center' },
  // { title: '课程包信息', dataIndex: 'content5', align: 'center' },
  { title: '消费金额', dataIndex: 'consme', align: 'center' },
  { title: '老师', dataIndex: 'isAgent', scopedSlots: { customRender: 'isAgent' }, align: 'center' },
  // { title: '最近登录时间', dataIndex: 'content2', scopedSlots: { customRender: 'content2' }, align: 'center' },
  { title: '操作', dataIndex: 'operation', scopedSlots: { customRender: 'operation' }, align: 'center' }
];
import Deliver from './student/deliver'
import * as http from '@/libs/examapi'
export default {
    name: 'students',
    components: { Deliver },
    data(){
        this.option = [
          { name: '昵称', val: 'nickname'},
          { name: '学号', val: 'num'},
          { name: '电话', val: 'mobile'}
        ]
        return{
          data:[],
          columns,
          searchInput: '',
          selectVal: 'nickname',
          deliverOpen: false,
          currentItem: null,
          filter:{},
          page:{
            pageSize: 15,
            page: 1
          },
          total: 0
        }
    },
    mounted(){
      this.getData()
    },
    methods:{
      changeAdmin(checked, id) {
        http.setUserAgent({uid: id, isadmin: checked?1:0}).then(res => {
          this.getData()
        })
      },
      getData(){
        http.getUserList({...this.page, ...this.filter}).then(res => {
          let {total, data} = res
          this.data = data
          this.total = total
        })
      },
      openClasses({id}){
        console.log(id)
        this.$router.push({
          path: '/exam/courseLimit',
          query:{
            id
          }
        })
      },
      onSearch(){
        this.filter = {}
        this.filter[this.selectVal] = this.searchInput
        this.getData()
      },
      changePage(current){
        this.page.page = current
        this.getData()
      }
    }
}
</script>
